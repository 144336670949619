<template>
  <div class="req-temp-container mt-6 enabled-products-list">
    <div slot="header">
      <h3 class="font-medium text-lg md:text-xl lg:text-3xl mb-10">Enabled products</h3>
    </div>
    <div class="mt-6">
      <vs-card class="w-full">
        <vs-table :data="products" class="stripes merch-list">
          <template slot="thead">
            <vs-th> Product ID </vs-th>
            <vs-th> Product Name</vs-th>
            <vs-th></vs-th>
          </template>
  
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.customPlanId">{{ tr.customPlanId }}</vs-td>
              <vs-td :data="tr.productName">{{ tr.productName }}</vs-td>
              <vs-td :data="tr" class="text-right"><a class="pl-5 pr-5 underline" @click="gotoDetail(tr._id, tr.productName)">View</a></vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
    </div>
  </div>
</template>

<script>

import { mapActions,  mapGetters, mapMutations } from "vuex";
import store from "../../../store/store";

export default {
  data() {
    return {
      products: [],
    };
  },
  methods: {
    ...mapActions("paymentPlan", ["fetchPaymentPlans"]),
    ...mapMutations(["setProductTitle"]),
    async getPaymentPlans() {
      const param = `${this.introducerId}`;
      this.$vs.loading();
      await this.fetchPaymentPlans(param).then((response) => {
        this.products = response.data.data.paymentPlans || [];
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage(ex.response.data.title,  ex.response.data.message, "error");
      });
    },
    gotoDetail(id, title) {
      this.setProductTitle(title);
      this.$router.push({ name: "product-detail", params: { id: id } });
    },
  },

  mounted() {
    this.getPaymentPlans();
  },

  computed: {
    user() {
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    introducerId() {
      return this.user.userType === "superAdmin"
        ? this.user._id
        : this.user.introducer;
    },
  },
};
</script>
